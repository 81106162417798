import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCard,{staticClass:"ma-3 pa-10",staticStyle:{"width":"400px"},attrs:{"elevation":"0"}},[_c('img',{attrs:{"src":require("../../assets/hsf-logo.jpg")}}),_c(VForm,[_c(VTextField,{staticClass:"mt-10",attrs:{"label":_vm.$t('username'),"clearable":"","outlined":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c(VTextField,{attrs:{"type":"password","autocomplete":"current-password","label":_vm.$t('password'),"clearable":"","outlined":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c(VBtn,{attrs:{"block":"","large":"","color":"primary","dark":"","elevation":"0","loading":_vm.loading},on:{"click":function($event){return _vm.performLogin()}}},[_vm._v(" "+_vm._s(_vm.$t('login'))+" ")])],1)],1)],1)],1)],1),_c(VSnackbar,{attrs:{"timeout":_vm.timeout,"color":"red","dark":"","centered":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.notificationbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}]),model:{value:(_vm.notificationbar),callback:function ($$v) {_vm.notificationbar=$$v},expression:"notificationbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }