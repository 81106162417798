
import Vue from 'vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

export default Vue.extend({
  name: 'LoginComponent',
  data: () => ({
    notificationbar: false,
    text: '' as any,
    timeout: 8000,
    loading: false,
    user: {
      username: '',
      password: ''
    }
  }),
  methods: {
    performLogin: function () {
      this.loading = true
      firebase.auth().signInWithEmailAndPassword(this.user.username, this.user.password).then(credentials => {
        const user = credentials.user
        if (user) {
          firebase.firestore().collection('users').doc(user.uid).get().then((doc) => {
            if (doc.exists) {
              const data = doc.data()
              if (data.role.indexOf('admin') === -1) {
                firebase.auth().signOut().then(() => {
                  this.$store.dispatch('SettingsModule/closeDBChannel', { clearModule: true })
                  this.loading = false
                  this.notificationbar = true
                  this.text = this.$t('not-authorized')
                })
              } else {
                this.$store.dispatch('user/fetchAndAdd').then(() => {
                  const lang = (data as any).language
                  localStorage.setItem('language', lang)
                  this.$i18n.locale = lang
                  this.$store.dispatch('SettingsModule/fetchAndAdd').then(() => {
                    this.$router.replace('/competitions')
                  })
                })
              }
            } else {
              this.loading = false
              this.notificationbar = true
              this.text = this.$t('user-not-found')
            }
          }).catch((error) => {
            this.loading = false
            this.notificationbar = true
            this.text = error
          })
        }
      }, () => {
        this.loading = false
        this.notificationbar = true
        this.text = this.$t('incorrect-login')
      })
    }
  }
})
